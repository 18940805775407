const baseColumn = vm => {
  return [
    {
      keyId: 12,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '线路名称',
      field: 'lineName',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      selectOptions: [{
        value:'',
        label:'全部线路'
      }],
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    }
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      keyId: 11,
      title: '线路ID',
      field: 'lineId',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 1,
      title: '线路名称',
      field: 'lineName',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'',
        label:'全部线路'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 2,
      title: '电子围栏出发点地址',
      field: 'startFenceAddress',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '电子围栏出发点中心点纬度',
      field: 'startFenceLat',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '电子围栏出发点中心点经度',
      field: 'startFenceLng',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '电子围栏半径',
      field: 'startFenceRange',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 6,
      title: '电子围栏到达点地址',
      field: 'endFenceAddress',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 7,
      title: '电子围栏到达点中心点纬度',
      field: 'endFenceLat',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 8,
      title: '电子围栏到达点中心点经度',
      field: 'endFenceLng',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 9,
      title: '电子围栏半径',
      field: 'endFenceRange',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 10,
      title: '创建时间',
      field: 'created',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
